<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
   const { sessionDetailAsClient } = storeToRefs(useClientVideoStore());
   import {
      CheckCircleIcon,
      StarIcon,
      XMarkIcon,
   } from '@heroicons/vue/24/outline';
   import formkitClasses from '~/const/formkitClasses';
   import type { ClientPendingBooking } from '~/types/clientInformation';
   const props = defineProps<{
      data?: ClientPendingBooking;
      isPending?: boolean;
   }>();
   const emits = defineEmits<{
      (event: 'close'): void;
      (event: 'success'): void;
   }>();
   const { sendClientFeedback } = useClientVideoStore();
   const { getSelectedTherapistInfo } = useClientInfo();
   const router = useRouter();
   const route = useRoute();
   const { t } = useI18n();

   const ratings = [1, 2, 3, 4, 5];

   const rating = ref(0);
   const feedback = ref();
   const loading = ref(false);

   const closeRating = async () => {
      await getSelectedTherapistInfo();
      if (!props.isPending)
         router.push({
            name: 'client-booking',
         });
      emits('close');
   };
   const sendFeedback = async () => {
      loading.value = true;
      await sendClientFeedback(
         (sessionDetailAsClient?.value?.id as string) ||
            (props.data?.id as string) ||
            (route?.params?.sessionId as string),
         {
            rating: rating.value,
            feedback: feedback.value,
         }
      );
      await closeRating();
      loading.value = false;
      if (props.data) {
         rating.value = 0;
         feedback.value = '';
         emits('success');
      }
   };
</script>
<template>
   
<FormKitLazyProvider config-file="true">
<div class="mb-4 flex gap-2 justify-between items-center">
      <div class="flex items-center gap-2">
         <CheckCircleIcon class="h-6 w-6 text-secondary-500"></CheckCircleIcon>
         <p class="text-gray-200 font-medium text-sm">
            {{ t('zoom.completed.successfully') }}
         </p>
      </div>
      <XMarkIcon
         @click.prevent="closeRating"
         class="text-black cursor-pointer w-7 h-7"
      />
   </div>
   <VideoCallProfileBanner
      :is-client="true"
      :data="{
         image: data?.therapist.image,
         name: data?.therapist.name,
         date: data?.date,
         time: data?.time,
      }"
   />
   <div
      class="pt-6 border-t w-full border-t-gray-25 flex flex-col gap-3 text-center mb-12"
   >
      <p class="text-sm text-primary-500 font-medium">
         {{ t('zoom.feedback_important') }}
      </p>
      <p class="text-lg font-medium">{{ t('zoom.helpfulness') }}</p>
      <p class="text-xs text-gray-200">
         {{ t('zoom.feedback_helps_improve_service') }}
      </p>
      <div class="flex gap-2 justify-center my-3">
         <template v-for="rate in ratings" :key="rate">
            <StarIcon
               @click.prevent="rating = rate"
               class="h-6 w-6 cursor-pointer"
               :class="
                  rate <= rating ?
                     'text-yellow-400 fill-current'
                  :  'text-primary-500'
               "
            />
         </template>
      </div>
      <transition name="slide-down">
         <FormKit
            v-if="rating"
            type="form"
            :incomplete-messages="false"
            @submit="sendFeedback"
            :actions="false"
         >
            <FormKit
               type="textarea"
               :placeholder="t('zoom.feedback_improves_service')"
               name="Bio"
               :classes="formkitClasses.textarea"
               v-model="feedback"
            ></FormKit>
            <FormKit
               :loading="loading"
               :classes="{ input: 'w-full' }"
               type="submit"
            >
               <BaseButton
                  color="primary-500"
                  class="w-full mt-3"
                  :loading="loading"
                  :disabled="loading"
               >
                  {{ t('zoom.submitFeedback') }}
               </BaseButton>
            </FormKit>
         </FormKit>
      </transition>
   </div>
</FormKitLazyProvider>

</template>
